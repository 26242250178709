import algoliasearch from 'algoliasearch';

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_CLIENT_SECRET as string,
);

export const searchQuery = (query: any, search: any): any => {
  search(query);
};
